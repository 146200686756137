import './loot.css';
import sod_items from './sod_items.json';
import era_items from './era_items.json';
import Item from'./item.js';
import {useEffect, useState, React} from 'react';

export function Loot()
{
    
    var sod_dungeons_list = ['Ragefire Chasm', 'Wailing Caverns', 'The Deadmines', 'Shadowfang Keep', 
        'The Stockade', 'Razorfen Kraul', 'Scarlet Monastery - Graveyard', 'Scarlet Monastery - Library',
        'Scarlet Monastery - Armory', 'Scarlet Monastery - Cathedral', 'Razorfen Downs', 'Uldaman', "Zul'Farrak", 'Maraudon',
        'Blackrock Depths', 'Lower Blackrock Spire', 'Upper Blackrock Spire', 'Dire Maul - East',
    'Dire Maul - West', 'Dire Maul - North', 'Scholomance', 'Stratholme', 'Demon Fall Canyon', 'Blackfathom Deeps', 'Gnomeregan', "The Temple of Atal'Hakkar",
    "Onyxia's Lair", 'Molten Core', "Zul'Gurub", 'Blackwing Lair', "Single Boss Raids"];

    var era_dungeons_list = ['Ragefire Chasm', 'Wailing Caverns', 'The Deadmines', 'Shadowfang Keep', 'Blackfathom Deeps',
    'The Stockade', 'Gnomeregan', 'Razorfen Kraul', 'Scarlet Monastery - Graveyard', 'Scarlet Monastery - Library',
    'Scarlet Monastery - Armory', 'Scarlet Monastery - Cathedral', 'Razorfen Downs', 'Uldaman', "Zul'Farrak", 'Maraudon',
    "The Temple of Atal'Hakkar", 'Blackrock Depths', 'Lower Blackrock Spire', 'Upper Blackrock Spire', 'Dire Maul - East',
    'Dire Maul - West', 'Dire Maul - North', 'Scholomance', 'Stratholme', "World Bosses", "Zul'Gurub", "Ruins of Ahn'Qiraj", 
    "Onyxia's Lair", 'Molten Core', 'Blackwing Lair', "Ahn'Qiraj", 'Naxxramas'];

    const [dungeons, setDungeons] = useState(era_dungeons_list)
    const [showBosses, setShowBosses] = useState([]);
    const [showItems, setShowItems] = useState([]);
    const [dungeon, setDungeon] = useState("");
    const [boss, setBoss] = useState("");
    const [wowVersion, setWowVersion] = useState("era"); // add local storage for memory
    const [items, setItems] = useState(sod_items)


    window.addEventListener("load", () => {
		if (localStorage.getItem('stored_version')){
            setWowVersion(localStorage.getItem('stored_version'));
        }
	});

    /*
    the four useEffects below power the logic of the site
    selecting a dungeon populates the possible bosses and defaults the selected boss to the first
    selecting a boss (incl default selection) updates the showItems array
    the showItems array determines which items are rendered to the page
    switching between wow versions will repopulate the rendered items if the dungeon/boss combination exists
        in the newly selected version, or else otherwise it will clear the selection and return to a freshly loaded state
    */

    useEffect(()=>{

        setShowBosses([]);
        let bosses_ = [];

        for (const key in items[dungeon])
        {
            bosses_.push(key);
        }

        setShowBosses(bosses_);
        setBoss("");

    }, [dungeon]);

    useEffect(()=>{
        
        if (!boss)
        {
            setBoss(showBosses[0]);
        } 

        if (showBosses.length === 0)
        {
            if (dungeon && Object.keys(items[dungeon]).includes(boss))
            {

                let bosses_ = [];
                for (const key in items[dungeon])
                {
                    bosses_.push(key);
                }
                setShowBosses(bosses_);
            } else {
                setDungeon("");
                setBoss("");
            }
        }

    }, [showBosses])

    useEffect(() => {

        setShowItems([])

        if (dungeon && boss)
        {
            setShowItems(previousItems => {
                const newItems = [... previousItems];
                for (const key in items[dungeon][boss])
                {
                    newItems.push(items[dungeon][boss][key])
                }
                return newItems;
            })
            
        }
    },[boss, dungeon, items]);

    useEffect(()=> {
    
        if (wowVersion === 'era')
        {
            setDungeons(era_dungeons_list);
            setShowItems([])
            setItems(era_items);
            if (dungeon)
            {
                setShowBosses([]);
            }
        } else {
            setDungeons(sod_dungeons_list);
            setShowItems([])
            setItems(sod_items);
            if (dungeon)
            {
                setShowBosses([]);
            }
        }

    },[wowVersion])


    // the html which allows users to select a dungeon and boss
    function ContentSelect()
    {
        return (
        <div className='content-select'>
            <select id='wow-select' value={wowVersion} onChange={(e)=>{
                    localStorage.setItem('stored_version', e.target.value);
                    setWowVersion(e.target.value)}}>
                <option value={'sod'} name={'sod'}>SoD</option>
                <option value={'era'} name={'era'}>Era</option>
            </select>

            <select value={dungeon} id='dungeon-select' onChange={(e)=>{setDungeon(e.target.value)}}>

                {!dungeon ? <option key={"default_d"} name={"default_d"}>Select Dungeon</option> : <></>}

                {dungeons.map((d) => {
                    return (<option value={d} key={d} name={d}>{d}</option>)
                })}

            </select>


            {showBosses.length > 0 ? <select value={boss} key={"default_b"} name={"default_d"} onChange={(e)=>{
                    setShowItems([]);
                    setBoss(e.target.value)
                }}>
                {showBosses.map((b) => {
                    return (<option value={b} key={b} name={b}>{b}</option>)
                })}


            </select> : <select className='disabled' name='disabled'><option>...</option></select>}

        </div>)
    }


    // render in two parts
    // content select is the two drop downs to select dungeon & boss
    // item-container is the space where a series of item objects are rendered
    //  see item class for item details
    return (
        <div>
            {<ContentSelect/>}
            <div className='item-container'>
                {showItems.map((i, k) => {
                    return (<Item props={i} key={k}/>)
                })}     
            </div>
        </div>
    )
}


/*

Lord Blackwood's Blade/Blade of Blackwood
cadaverous

dm class books

MC tier tokens?
    removed tier + draconic for dummies from mc (and ony)

removed dragons from world boss list
    wb loot might not be done

new relics drop where?
https://www.wowhead.com/classic/news/12-new-endgame-relics-coming-in-season-of-discovery-phase-4-343778

crafted stats
    black dragonscale/volcanic armor tierset?
    hammer of the titans recipe drop
    arcantie reaper recipe drop
    arcanite champion

mc loot	https://www.wowhead.com/classic/guide/season-of-discovery/raids/molten-core-overview-loot#updated-loot
https://www.wowhead.com/classic/news/new-and-revamped-epics-from-molten-core-in-season-of-discovery-345488

Goblino Noir
Prestor's Hairpin
Goblin Clothesline
Makeshift South Sea Oar
The Attitude Adjustor
Goblin Gear Grinder
Dragonslayer's Javelin
Deceit
The Molten Core

https://www.wowhead.com/classic/news/itemization-updates-to-blackwing-lair-loot-season-of-discovery-phase-ptr-346321
https://www.wowhead.com/classic/news/itemization-updates-to-zulgurub-boss-loot-season-of-discovery-phase-5-ptr-346324

turn analytics back on

<!-- If you're reading this and you want to buy me a coffee:
    https://www.paypal.com/donate/?business=7KDTWTUFK9X9S&no_recurring=1&item_name=I+hope+you+liked+the+site%21&currency_code=USD -->
*/